<template>
    <div class="wrap">
        <div class="main_title">Tasks</div>
        <div class="h100" style="overflow: auto;">
            <a-spin :spinning="spinning">
                <a-empty v-if="tasks.length == 0" />
                <div class="tasks" v-else>
                    <div class="tasks_item" v-for="(task, idx) in tasks" :key="idx">
                        <div class="tasks_left">
                            <div class="tasks_left" style="width: auto">
                                <div class="icon">
                                    <img src="/img/icons/task_icon.png" class="icon_img">
                                </div>
                            </div>
                            <div class="tasks_right" style="width: 90%">
                                <div class="flex_column">
                                    <div class="box">
                                        <strong class="no">Order no: {{task.om.order_no || '-'}}</strong>
                                    </div>
                                    <div class="flex">
                                        <div class="box">
                                            <strong class="value">{{getDate(task.created_time)}}</strong>
                                            <span class="name">Create date</span>
                                        </div>
                                        <div class="box">
                                            <strong class="value">{{getDate(task.updated_time)}}</strong>
                                            <span class="name">Update date</span>
                                        </div>
                                        <div class="box">
                                            <strong class="value">{{task.tdm ? task.tdm.department_name : '-'}}</strong>
                                            <span class="name">Current Department</span>
                                        </div>
                                        <div class="box">
                                            <strong class="value">{{task.tem ? task.tem.username : '-'}}</strong>
                                            <span class="name">Current Employee</span>
                                        </div>
                                        <div class="box">
                                            <strong v-if="task.state == 0" class="value pending">Pending</strong>
                                            <strong v-if="task.state == 1" class="value finish">Finish</strong>
                                            <span class="name">Status</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tasks_right">
                            <a-button type="primary" class="btn primary" shape="round" @click="checkDetail(task)">
                                <template #icon>
                                <component :is="$antIcons['AppstoreAddOutlined']" />
                                </template>
                                Detail
                            </a-button>
                        </div>
                    </div>
                </div>
            </a-spin>
        </div>
        <a-modal class="task_wrap" v-model:visible="modalStatus" width="1000px" title="Task detail" @ok="onOk">
            <div class="topbar">
                <div class="status pending" v-if="taskInfo.state == 1"><i class="circle"></i>Finish</div>
                <div class="status finish" v-if="taskInfo.state == 0"><i class="circle"></i>Pending</div>
                <a-space>
                    <a-button type="primary" v-if="getAction(3, isAdmin) && taskInfo.state == 0" size="small" @click="openOrder">Get order</a-button>
                    <!-- <a-button type="primary" v-if="getAction(5, isAdmin)" class="dist" @click="modalDistStatus = true" size="small">Distribute</a-button> -->
                    <a-button type="primary" v-if="getAction(6, isAdmin) && taskInfo.state == 0" class="send" @click="modalSendStatus = true" size="small">Send</a-button>
                    <a-popconfirm
                        title="Are you sure finish this task?"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="finishHandler"
                    >
                        <a-button type="primary" v-if="getAction(7, isAdmin) && taskInfo.state == 0" class="primary btn" size="small">Finish</a-button>
                    </a-popconfirm>
                    <a-button size="small" v-if="getAction(1, isAdmin)" @click="getTaskLogsHandler">Get logs</a-button>
                </a-space>
            </div>
            <TaskProgress :taskInfo="taskInfo" />
            <DesignPlan  ref="design" v-if="getDepClass == 1 || getDepClass == 2 || getDepClass == 6" :taskInfo="taskInfo"  />
            <Installation  ref="installation" v-if="getDepClass == 1 || getDepClass == 3 || getDepClass == 6" :taskInfo="taskInfo" />
            <GoodsList  ref="goodsList" v-if="getDepClass == 1 || getDepClass == 4 || getDepClass == 6" :taskInfo="taskInfo" />
            <Contract  ref="contract" v-if="getDepClass == 1 || getDepClass == 5 || getDepClass == 6" :taskInfo="taskInfo" />
        </a-modal>
        <a-modal v-model:visible="modalLogsStatus" :ok-button-props="{style:{display:'none'}}" okText="" width="1000px" title="Task logs">
            <div class="logs">
                <div class="cell" v-for="(log, index) in logsList" :key="index">
                    <span class="user">{{log.fdm.department_name}}/{{log.fem.username}}</span>
                    <span class="action">{{getActionName(log.action)}} <em v-if="log.action != 7">----></em></span>
                    <span class="user">{{log.tdm.department_name}}/{{log.tem.username}}</span>
                    <span class="date">on {{getDate(log.created_time)}}</span>
                </div>
            </div>
        </a-modal>
        <a-drawer
            title="Order"
            placement="bottom"
            height="100%"
            :visible="modalOrderStatus"
            @close="() => modalOrderStatus = false"
            :drawerStyle="{background: '#f9f9f9'}"
            >
               <div class="container">
                   <OrderInfo :orderInfo="taskInfo.om" :isAdmin="isAdmin" />
               </div>
        </a-drawer>
        <a-modal v-model:visible="modalDistStatus" okText="" width="400px" @ok="onDist" title="Distribute task">
            <a-form :model="dist" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-item label="Department">
                    <a-select
                        ref="select"
                        v-model:value="dist.dep_id"
                        :options="depList"
                        placeholder="Select department"
                        @change="changeDepartment"
                    >
                    </a-select>
                </a-form-item>
                <a-form-item label="Employee">
                     <a-select
                        ref="select"
                        v-model:value="dist.emp_id"
                        :options="empList"
                        placeholder="Select employee"
                    >
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model:visible="modalSendStatus" @ok="onSend" width="400px" title="Send task">
            <a-form :model="send" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-item label="Department">
                    <a-select
                        ref="select"
                        v-model:value="send.dep_id"
                        :options="depList"
                        placeholder="Select department"
                        @change="changeDepartment"
                    >
                    </a-select>
                </a-form-item>
                <a-form-item label="Employee">
                     <a-select
                        ref="select"
                        v-model:value="send.emp_id"
                        :options="empList"
                        placeholder="Select employee"
                    >
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script>
import moment from 'moment'
import { message } from 'ant-design-vue'
import commonMixins from '@/mixins/commonMixins'
import { getDepartmentList, getEmployeeList } from '@/apis/employee/employee'
import { getTaskListForAdmin } from '@/apis/task'
import { getTaskListForEmployee, distTask, sendTask, finishTask, getTaskLogs,getTaskStreamAll } from '@/apis/employee/task'
import OrderInfo from '@/components/admin/order/orderInfo'
import TaskProgress from './components/task_progress.vue'
import DesignPlan from './components/design_plan.vue'
import Installation from './components/installation'
import GoodsList from './components/goods_list'
import Contract from './components/contract'
export default {
    name: 'Tasks',
    mixins: [commonMixins],
    props: {
        isAdmin: {
            type: Boolean,
            default: true
        }
    },
    components: {
        OrderInfo,
        TaskProgress,
        DesignPlan,
        Installation,
        GoodsList,
        Contract
    },
    data(){
        return {
            query: {
                page: 1,
                page_size: 10
            },
            tasks: [],
            taskInfo: {},
            modalStatus: false,
            modalLogsStatus: false,
            modalOrderStatus: false,
            modalDistStatus: false,
            modalSendStatus: false,
            spinning: false,
            send: {dep_id: undefined, emp_id: undefined},
            dist: {dep_id: undefined, emp_id: undefined},
            depList: [],
            empList: [],
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
            logsList: [],
            born: 0
        }
    },
    methods: {
        async checkDetail(taskInfo){
            this.taskInfo = taskInfo
            const ts=await getTaskStreamAll({task_id:this.taskInfo.task_id})
            // console.log('tsinfo',this.taskInfo)
            this.taskInfo.om.orderStreams=ts.ts[0]
            if(this.born==0){
                this.born++
            }else{
                this.$nextTick(() => {
                    this.$refs.design.getTaskFileHandler();
                    this.$refs.installation.getTaskFileHandler();
                    this.$refs.contract.getTaskFileHandler();
                })
            }
            this.modalStatus = true
        },
        async onOk(){
            this.modalStatus = false
        },
        openOrder(){
            this.modalOrderStatus = true
        },
        async getTaskListHandler(){
            this.spinning = true
            let res
            if(this.isAdmin){
                res = await getTaskListForAdmin(this.query)
            }else{
                res = await getTaskListForEmployee(this.query)
            }
            this.spinning = false
            if(res){
                const list = res.list.rows.sort((a, b) => {
                    return a.state > b.state
                })
                this.tasks = list.reverse()
            }
        },
        async getDepartmentListHandler(){
            if(!this.isAdmin){
                const res = await getDepartmentList()
                this.depList = res.list.map(r => {
                    r.label = r.department_name
                    r.value = r.dep_id
                    return r
                })
            }
        },
        async getEmployeeListHandler(dep_id){
            const res = await getEmployeeList({dep_id, page_size: 1000})
            this.empList = res.list.rows.map(r => {
                r.label = r.username
                r.value = r.emp_id
                return r
            })
        },
        changeDepartment(depId){
            this.getEmployeeListHandler(depId)
        },
        /**
         * 分配任务
         */
        async onDist(){
            const params = {
                task_id: this.taskInfo.task_id,
                to_dep: this.dist.dep_id,
                to_emp: this.dist.emp_id,
                remark: this.dist.remark
            }
            const res = await distTask(params)
            message.success('Success')
            this.modalDistStatus = false
            this.getTaskListHandler()
        },
        /**
         * 发送任务
         */
        async onSend(){
            const params = {
                task_id: this.taskInfo.task_id,
                to_dep: this.send.dep_id,
                to_emp: this.send.emp_id,
                remark: this.send.remark
            }
            const res = await sendTask(params)
            message.success('Success')
            this.modalSendStatus = false
            this.getTaskListHandler()
        },
        /**
         * 完成任务
         */
        async finishHandler(){
            const params = {
                task_id: this.taskInfo.task_id,
            }
            const res = await finishTask(params)
            message.success('Success')
            this.modalStatus = false
            this.getTaskListHandler()
        },
        /**
         * 获取任务日志
         */
        async getTaskLogsHandler(){
            this.modalLogsStatus = true
            const res = await getTaskLogs({task_id: this.taskInfo.task_id})
            this.logsList = res.list.rows
        }
    },
    computed: {
        getDate(){
            return ts => {
                return moment(Number(ts) * 1000).format('YYYY-MM-DD HH:mm:ss')
            }
        }
    },
    created(){
        this.getTaskListHandler()
        this.getDepartmentListHandler()
    }
}
</script>
<style lang="stylus" scoped>
.logs
    .cell
        padding 10px 0
        border-bottom 1px solid #eee
        display flex
        justify-content space-between
        align-items center
        .user
           font-size 16px
        .date
           font-size 12px
           color #aaa
        .action
           color #43af52
</style>