<template>
    <div class="info">
        <div class="titlebar">Contract</div>
        <a-spin :spinning="spinning">
            <div class="filelist">
                <a-row :gutter="10">
                    <a-col :span="4" v-for="(file, index) in fileList" :key="index">
                        <div class="filebox" @click="downloadFile(file.name)">
                            <span v-if="taskInfo.state == 0" class="close" @click.stop="() => fileList.splice(index, 1)"><component :is="$antIcons['CloseCircleOutlined']" /></span>
                             <component class="file_icon" :is="$antIcons['FileZipOutlined']" />
                        </div>
                    </a-col>
                </a-row>
            </div>
            <div class="order_info">
                <a-upload v-if="taskInfo.state == 0" :customRequest="(file) => customRequest(file.file)" :showUploadList="false">
                    <a-button class="primary btn " size="small">
                        <component :is="$antIcons['UploadOutlined']" />
                        Upload
                    </a-button>
                </a-upload>
            </div>
            <div class="bottom">
                 <a-button type="primary" v-if="taskInfo.state == 0" size="small" @click="updateTaskFileHandler">Submit</a-button>
            </div>
        </a-spin>
    </div>
</template>
<script>
import { message } from 'ant-design-vue'
import moment from 'moment'
import commonMixins from '@/mixins/commonMixins'
import { upload } from '@/apis/employee/system'
import { getTaskFile, updateTaskFile } from '@/apis/employee/task'
export default {
    name: 'DesignPlan',
    props: ['taskInfo'],
    mixins: [commonMixins],
    data(){
        return {
            spinning: false,
            fileList: [],
        }
    },
    methods: {
        /**
         * 图片上传
         */
        async customRequest(file){
            this.spinning = true
            const res = await upload(file)
            this.fileList.push(res)
            this.spinning = false
        },
        /**
         * 获取任务文件
         */
        async getTaskFileHandler(){
            const res = await getTaskFile({task_id: this.taskInfo.task_id, type: 'contract'})
            this.fileList = res.list || []
            console.log('contract',this.fileList)
        },
        async updateTaskFileHandler(){
            const json = this.fileList.map(r => {
                return {name: r.name}
            })
            const params = {
                task_id: this.taskInfo.task_id, 
                type: 'contract',
                json: json
            }
            const res = await updateTaskFile(params)
            message.success('Success')
        }
    },
    computed: {
        getDate(){
            return ts => {
                return moment(Number(ts) * 1000).format('YYYY-MM-DD HH:mm:ss')
            }
        }
    },
    created(){
        this.getTaskFileHandler()
    }
}
</script>
<style lang="stylus" scoped>
.order_info
    padding 20px
</style>