<template>
    <div class="info">
        <div class="titlebar">Task progress</div>
        <div class="order_info">
            <a-row :gutter="10">
                <a-col :span="6">
                    <div class="info_item">
                        <div class="value">{{taskInfo.task_id}}</div>
                        <div class="name">Task id</div>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="info_item">
                        <div class="value">{{taskInfo.om.order_no}}</div>
                        <div class="name">Order no</div>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="info_item">
                        <div class="value">{{getDate(taskInfo.created_time)}}</div>
                        <div class="name">Create date</div>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="info_item">
                        <div class="value">{{getDate(taskInfo.updated_time)}}</div>
                        <div class="name">Update date</div>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="info_item">
                        <div class="value">{{taskInfo.tdm ? taskInfo.tdm.department_name : '-'}}</div>
                        <div class="name">Current department</div>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="info_item">
                        <div class="value">{{taskInfo.tem ? taskInfo.tem.username : '-'}}</div>
                        <div class="name">Current employee</div>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="info_item">
                        <div class="value">{{taskInfo.fdm ? taskInfo.fdm.department_name : '-'}}</div>
                        <div class="name">Be from department</div>
                    </div>
                </a-col>
                <a-col :span="6">
                    <div class="info_item">
                        <div class="value">{{taskInfo.fem ? taskInfo.fem.username : '-'}}</div>
                        <div class="name">Be from employee</div>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
export default {
    name: 'TaskProgress',
    props: ['taskInfo'],
    computed: {
        getDate(){
            return ts => {
                return moment(Number(ts) * 1000).format('YYYY-MM-DD HH:mm:ss')
            }
        }
    },
}
</script>